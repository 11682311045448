/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Petstore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    tag?: string;
}

/**
 * PetsApi - axios parameter creator
 * @export
 */
export const PetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPets: async (pet: Pet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pet' is not null or undefined
            if (pet === null || pet === undefined) {
                throw new RequiredError('pet','Required parameter pet was null or undefined when calling createPets.');
            }
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof pet !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(pet !== undefined ? pet : {})
                : (pet || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById: async (petId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            if (petId === null || petId === undefined) {
                throw new RequiredError('petId','Required parameter petId was null or undefined when calling deletePetById.');
            }
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets: async (limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById: async (petId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            if (petId === null || petId === undefined) {
                throw new RequiredError('petId','Required parameter petId was null or undefined when calling showPetById.');
            }
            const localVarPath = `/pets/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetsApi - functional programming interface
 * @export
 */
export const PetsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPets(pet: Pet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PetsApiAxiosParamCreator(configuration).createPets(pet, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePetById(petId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PetsApiAxiosParamCreator(configuration).deletePetById(petId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPets(limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pet>>> {
            const localVarAxiosArgs = await PetsApiAxiosParamCreator(configuration).listPets(limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showPetById(petId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await PetsApiAxiosParamCreator(configuration).showPetById(petId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PetsApi - factory interface
 * @export
 */
export const PetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a pet
         * @param {Pet} pet A *Pet* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPets(pet: Pet, options?: any): AxiosPromise<void> {
            return PetsApiFp(configuration).createPets(pet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Pet by id
         * @param {number} petId The id of the pet to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePetById(petId: number, options?: any): AxiosPromise<void> {
            return PetsApiFp(configuration).deletePetById(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all pets
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPets(limit?: number, options?: any): AxiosPromise<Array<Pet>> {
            return PetsApiFp(configuration).listPets(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific pet
         * @param {number} petId The id of the pet to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPetById(petId: number, options?: any): AxiosPromise<Pet> {
            return PetsApiFp(configuration).showPetById(petId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetsApi - object-oriented interface
 * @export
 * @class PetsApi
 * @extends {BaseAPI}
 */
export class PetsApi extends BaseAPI {
    /**
     * 
     * @summary Create a pet
     * @param {Pet} pet A *Pet* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public createPets(pet: Pet, options?: any) {
        return PetsApiFp(this.configuration).createPets(pet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Pet by id
     * @param {number} petId The id of the pet to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public deletePetById(petId: number, options?: any) {
        return PetsApiFp(this.configuration).deletePetById(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all pets
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public listPets(limit?: number, options?: any) {
        return PetsApiFp(this.configuration).listPets(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific pet
     * @param {number} petId The id of the pet to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsApi
     */
    public showPetById(petId: number, options?: any) {
        return PetsApiFp(this.configuration).showPetById(petId, options).then((request) => request(this.axios, this.basePath));
    }
}


