import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Link from '@amzn/awsui-components-react/polaris/link';
import CSS from 'csstype';

const styles: CSS.Properties = {
  background: "rgb(15, 27, 42)",
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  margin: 0,
  height: '50px',
}

export default function Header() {
  return (
    <header style={styles}>
      <Box padding={{ vertical: 's', horizontal: 's' }}>
          <div className="custom-home-text-inverted">
            <h3 style={{color: 'white'}}>LSE Portal</h3>
          </div>
      </Box>
    </header>
  );
}