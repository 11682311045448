import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import { ServiceNavigation } from '../navigation';
import { appLayoutNavigationLabels } from '../../common/labels';

export default function Homepage() {
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      content={<HomepageContent />}
      contentType="default"
      toolsHide={true}
      disableContentPaddings={true}
      ariaLabels={appLayoutNavigationLabels}
    />
  );
}
