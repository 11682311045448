import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import Link from '@amzn/awsui-components-react/polaris/link';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';

const HOME = { text: 'ServiceName', href: '#/' };
const items: any[] = [
  {
    type: 'section',
    text: 'Demo pages',
    items: [
      { type: 'link', text: 'Basic page', href: '#/basic' },
      { type: 'link', text: 'Create page', href: '#/form/create/1' },
      {
        type: 'expandable-link-group',
        text: 'Nested Section',
        items: [
          { type: 'link', text: 'Basic page', href: '#/basic' },
          { 
            type: 'expandable-link-group',
            text: 'Nested Section 2',
            items: [
              { type: 'link', text: 'Basic page', href: '#/basic' },
              { type: 'link', text: 'Create page', href: '#/form/create/1' }
            ]
           }
        ]
      }
    ]
  }
];

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  return <BreadcrumbGroup items={items} />;
}

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends Component<RouteComponentProps> {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
    event.preventDefault();
    if (event.detail.href) {
      this.props.history.push(event.detail.href.substring(1));
    }
  }

  render() {
    return (
      <SideNavigation
        header={HOME}
        items={items}
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
