// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import { initializeAppSettings } from './config/AppSettings';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

declare var process : {
  env: {
    NODE_ENV: string
  }
}

(async () => {
  if (process.env.NODE_ENV === 'production') {
    await getMidwayJwtToken();
  
  // Initialize application settings
  const appSettings = (await axios('../localStorage/settings.json')).data;
  initializeAppSettings(appSettings);
  }

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
})();
