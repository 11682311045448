import { Route, Switch } from 'react-router-dom';
import Homepage from './home';
import Header from './Header';
import FormPage from './form/FormPage';
import BasicLayout from './basic';

export default function App() {
  return (
    <div>
      <Header />
      <div style={{marginTop: 50}}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/form/:mode/:id" component={FormPage} />
          <Route path="/basic" component={BasicLayout} />
        </Switch>
      </div>
    </div>
  );
}
